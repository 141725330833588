<template>
  <div class="content">
    <van-nav-bar title="并发症列表" left-text="返回" right-text="" left-arrow @click-left="onClickLeft" />
    <div class="x-table">
      <van-row class="x-com x-com-th" justify="space-between">
        <van-col span="17">并发症列表</van-col>
        <van-col span="7">保险金额</van-col>
      </van-row>
      <van-row class="x-com x-com-tb" justify="space-between" v-for="(item,index) in explain" :key="index">
        <van-col span="17">{{item.content || ''}}</van-col>
        <van-col span="7">{{item.amount || 0}}</van-col>
      </van-row>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: {
    ...mapState({
      explain: "explain",
    }),
  },
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
  },
  mounted() {
    document.title = "并发证说明";
  },
};
</script>
<style lang="less" scoped>
.x-table {
  margin: 16px;

  .x-com {
    box-sizing: border-box;
  }
  .x-com-th {
    background: #e6e6e6;
    font-size: 14px;
    font-weight: 500;
    color: #1a1a1a;

    .van-col:nth-child(1) {
      border-right: 1px solid white;
      text-align: left;
    }
    .van-col:nth-child(2) {
      text-align: center;
    }
  }

  .x-com-tb {
    background-color: white;
    font-size: 14px;
    font-weight: 400;
    color: #4d4d4d;
    border-top: 1px solid #f2f2f2;

    .van-col:nth-child(1) {
      border-right: 1px solid #f2f2f2;
      text-align: left;
    }

    .van-col:nth-child(2) {
      text-align: center;
    }
  }
  .van-col {
    padding: 12px;
  }
}
</style>